import request from "@/utils/request"

const moduleName = "/certificate"
// 证书管理
export default {
	// 获取证书列表 分页
	getCertListPage: data =>
		request(`${moduleName}/list-page`, { method: "get", params: data }),
	// 新增证书
	addCert: data =>
		request(`${moduleName}/save`, { method: "post", body: data }),
	// 修改证书
	editCert: data =>
		request(`${moduleName}/modify`, { method: "put", body: data }),
	// 删除证书
	delCert: id => request(`${moduleName}/${id}`, { method: "delete" }),
	// 获取持证人员列表 分页
	getCertUserListPage: data =>
		request(`${moduleName}/list-user-page`, {
			method: "get",
			params: data
		}),
	// 获取持证人员 不分页
	apiGetUserData: data =>
		request(`${moduleName}/list-user`, { method: "get", params: data }),
	// 新增持证人员
	addCertUser: data =>
		request(`${moduleName}/save-user`, { method: "post", body: data }),
	// 修改持证人员
	editCertUser: data =>
		request(`${moduleName}/modify-user`, { method: "put", body: data }),
	// 删除持证人员
	delCertUser: id => request(`${moduleName}/user/${id}`, { method: "delete" })
}
