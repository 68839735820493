<template>
	<!--增加人员-->
	<Modal
		:title="title"
		:value="visible"
		:mask-closable="false"
		@on-cancel="onCancel"
		class="trainCert-add-user-wrapper"
	>
		<Spin fix v-if="loading"></Spin>
		<Form
			:model="formData"
			style="padding-bottom: 20px"
			ref="trainCertUserForm"
			label-position="top"
			:rules="rulesValid"
		>
			<Row :gutter="32">
				<Col span="24">
					<FormItem label="姓名" prop="username">
						<Input
							v-model="formData.username"
							:maxlength="10"
							placeholder="请输入姓名"
						/>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="职务" prop="position">
						<Input
							v-model="formData.position"
							:maxlength="10"
							placeholder="请输入职务"
						/>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="职称" prop="jobTitle">
						<Input
							v-model="formData.jobTitle"
							:maxlength="10"
							placeholder="请输入职称"
						/>
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer" class="drawer-footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk" :loading="loading">确定</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/cert/cert"

const { editCertUser, addCertUser } = api

export default {
	name: "AddUser",
	props: ["visible", "id", "currentRow"],
	data() {
		return {
			loading: false,
			formData: {},
			title: "",
			rulesValid: {
				username: {
					required: true,
					message: "姓名不能为空",
					trigger: "blur"
				},
				jobTitle: {
					required: true,
					message: "职称不能为空",
					trigger: "blur"
				},
				position: {
					required: true,
					message: "职务不能为空",
					trigger: "blur"
				}
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.trainCertUserForm.resetFields()
				this.formData = {}
				this.title = "新增持证人员"
				if (this.id) {
					this.title = "修改持证人员"
					this.formData = {
						...this.currentRow
					}
				}
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		async onOk() {
			const valid = await this.$refs.trainCertUserForm.validate()

			if (valid) {
				this.loading = true
				let res = false
				if (this.id) {
					res = await editCertUser({
						...this.formData
					})
				} else {
					res = await addCertUser({
						...this.formData
					})
				}
				if (res) {
					this.$emit("onOk")
					this.$emit("onCancel")
					this.$Message.success("操作成功！")
				}
				this.loading = false
			}
		}
	}
}
</script>

<style lang="less"></style>
